@use 'assets/styles/utils/helper' as h;

.backToTop {
  --btt--primary--color: var(--default-color);
  --btt--inverse--color: var(--white);

  :global(body.msnbc) & {
    --btt--primary--color: var(--blue-40);
  }

  :global(body.select) & {
    --btt--primary--color: var(--blue-60);
  }

  :global(body.today) & {
    --btt--primary--color: var(--accent-blue);
    --btt--inverse--color: var(--grey-70);
    --btt--hover--primary-color: var(--accent-purple);
    --btt--hover--inverse-color: var(--grey-70);
  }
}

@use 'assets/styles/utils/helper' as h;

.article-body__section--wells-fargo__banner {
  .article-body__section--wells-fargo__banner--link {
    display: block;
  }
}

.article-body .article-body__section--wells-fargo__banner {
  width: 100%;
  max-width: 1000px;
}

@use 'assets/styles/utils/helper' as h;

.outerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mostPopularList {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  font-family: FoundersGroteskCond, 'Arial Narrow', Arial, sans-serif;
  font-weight: normal;
  color: #2a2a2a;
  border-top: 1px solid #999;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 3rem;

  @include h.breakpoint-m-max {
    margin-left: 30px;
    margin-right: 30px;
  }

  @include h.breakpoint-s-only {
    margin-left: 20px;
    margin-right: 20px;
  }

  &::before {
    content: '';
    height: 8px;
    width: 80px;
    background-color: #3061ff;
  }

  .itemContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    left: -22px;

    @include h.breakpoint-s-only {
      flex-direction: column;
      align-items: flex-start;
      left: unset;
    }

    .popularItem {
      flex-basis: 33.33333%;
      font-family: FoundersGroteskCond, 'Arial Narrow', Arial, sans-serif;
      font-weight: 100;
      list-style-type: none;
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;

      a {
        display: block;
        font-size: 18px;
        line-height: 1;
        padding: 0;
      }

      &:nth-child(2) {
        order: 4;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(4) {
        order: 5;
      }

      &:nth-child(5) {
        order: 3;
      }

      &:nth-child(6) {
        order: 6;
      }

      @include h.breakpoint-s-only {
        width: 100%;
        flex-direction: column;
        align-items: start;
        order: unset;
        margin-bottom: 36px;

        > div {
          width: 100%;
        }

        &:nth-child(2) {
          order: unset;
        }

        &:nth-child(3) {
          order: unset;
        }

        &:nth-child(4) {
          order: unset;
        }

        &:nth-child(5) {
          order: unset;
        }

        &:nth-child(6) {
          order: unset;
        }
      }
    }
  }

  h3 {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 28px;
    font-weight: 600;
    line-height: 32px;

    @include h.breakpoint-s-only {
      font-size: 26px;
    }
  }
}

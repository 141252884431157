@use 'assets/styles/utils/helper' as h;

.article-inline-byline {
  --article-body--byline--color: inherit;
  --article-body--byline--font-family: var(--founders-cond);
  --article-body--byline--font-size: var(--text-20);
  --article-body--byline--font-weight: 400;
  --article-body--byline--line-height: 1;
  --article-body--byline--margin: 0;

  body.select & {
    --article-body--byline--color: #2a2a2a;
    --article-body--byline--font-family: var(--lato);
    --article-body--byline--font-size: var(--text-20);
    --article-body--byline--font-weight: 900;
    --article-body--byline--margin: 0 0 #{h.rem(32px)} 0;
  }

  body.today & {
    --article-body--byline--margin: 0 0 #{h.rem(32px)} 0;
    --article-body--byline--font-family: var(--primary-font);
    --article-body--byline--line-height: 1.5;

    @include h.breakpoint-m {
      --article-body--byline--margin: 0 0 #{h.rem(48px)} 0;
      --article-body--byline--font-size: #{h.rem(24px)};
    }

    @include h.breakpoint-l {
      --article-body--byline--margin: 0 0 #{h.rem(32px)} 0;
    }
  }
}

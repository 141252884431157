@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

@keyframes zoomAndFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.chat-blog-card {
  --article-body-ol-li-margin: 0 0 #{h.rem(24px)} 6px;
  --article-body-ol-font-size: 18px;
  scroll-margin-top: 68px;
  padding: 20px 0;

  &::before {
    border-bottom: h.rem(2px) var(--chat-card--border-style) var(--cc--before--border-color);
    content: '';
    display: block;
    width: 100%;
  }

  > h3,
  a > h3,
  a > h2 {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    font-size: 20px !important;
  }

  > h4,
  a > h4 {
    font-size: 18px !important;
  }

  @include h.breakpoint-s-only {
    > p,
    .body-list-el li {
      font-size: var(--lbc-small--font-size);
    }
  }

  .body-list-el,
  > .liftout,
  > .related {
    margin: h.rem(24px) 0;
  }

  > .blockquote {
    margin: h.rem(48px 0 32px 9px);
  }

  > .pullquote {
    margin: h.rem(48px) 0 h.rem(32px);
  }

  .pullquote__quote,
  .liftout__text {
    font-size: var(--lbc--pq-lo--font-size);
    font-weight: var(--lbc--pq-lo--font-weight);
    line-height: var(--lbc--pq-lo--line-height);
  }

  figcaption {
    margin-bottom: h.rem(24px);
  }

  &__byline-thumbnail {
    picture {
      width: h.rem(36px);
      margin: h.rem(24px);
    }

    a:hover {
      opacity: 1;
    }
  }

  p a:hover {
    opacity: var(--opacity-70);
  }

  .hidden-show-more-content {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  .time-stamp-social-share-container {
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
  }

  .authors-container {
    font-family: var(--oswald-font-family);
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
  }

  &.isReply {
    padding: 12px 0;

    &::before {
      border-bottom: none;
    }
  }
}

:global {
  // Increased specificity to override article-body styles
  .article-body .article-body__content :local(.chat-blog-card) {
    .break-above::before {
      content: none;
    }

    .embed-widget {
      margin: h.rem(12px) 0;
    }

    .body-list-el {
      margin-top: 1.25rem;
    }

    .body-graf {
      margin-top: 0;
      margin-bottom: 18px;
    }

    .body-list-el:last-child {
      margin-bottom: 0;
    }

    .body-list-el li::before {
      vertical-align: baseline;
      width: auto;
      margin-right: 0.5em;
    }

    :local(.authors-container) {
      a,
      a:link,
      a:hover {
        color: var(--chat-blog--author-name--color);
      }
    }
  }
}

.cardsContainer > :not(.lastCard).card:last-child {
  // not pretty but Intersection Observer currently creates another <div> which would enable this rule
  height: 148px;
  overflow: hidden;
  position: relative;

  &::after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, rgba(255, 255, 255, 0.78648) 56.77%, #fff 100%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.card.newActiveItem {
  animation: zoomAndFadeIn 0.3s ease-in 0s 1 normal forwards;
}

.share-list__wrapper {
  display: flex;
  align-items: center;

  .share__link-copy {
    margin: h.rem(0 12px);

    .icon-link {
      color: var(--lbc--share-link--color);

      &:hover {
        opacity: var(--opacity-70);
      }
    }
  }

  .share__sms {
    margin-left: h.rem(12px);
    margin-right: h.rem(12px);
    line-height: 0;

    .icon-sms {
      height: var(--lbc--share-link--height);
      width: var(--lbc--share-link--width);

      path {
        fill: var(--lbc--share-link--color);
      }
    }
  }
}

.author-thumbnails {
  --hover-area: 5px;
  display: flex;
  align-items: center;
  padding: var(--hover-area); /* Increase the tap area */
  margin: calc(var(--hover-area) * -1); /* Counteract the padding */
}

.author-dropdown {
  display: none;
  position: absolute;
  top: 38px;
  background-color: var(--white);
  border: 1px solid var(--grey-60);
  padding: 12px;
  list-style: none;
  width: 335px;
  z-index: h.get-z-index('liveblog-expanded-authors-dropdown');
  box-shadow: 1px 3px 2px 0 #00000040;
}

@mixin displayDropDown {
  .author-dropdown {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
}

.author-thumbnails.isTapped {
  @include displayDropDown();
}

@media (hover: hover) {
  .author-thumbnails:hover {
    @include displayDropDown();
  }
}

.dropdown-item {
  margin: 0;
}

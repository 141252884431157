@use './theme.tokens' as t;

.loadMoreButton {
  font-family: var(--founders-cond);
  height: 66px;
  width: 359px;
  flex-basis: auto;
  border: 2px solid var(--blue-40);
  color: var(--live-blog-button-font-color);
  background-color: var(--live-blog-button-background-color);

  &:active {
    opacity: var(--live-blog-button-active-opacity);
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    color: var(--live-blog-hover-button-font-color);
    background-color: var(--live-blog-hover-button-background-color);
  }

  &--loading {
    color: var(--live-blog-loading-button-font-color);
    background-color: var(--live-blog-loading-button-background-color);
  }
}

@use 'assets/styles/utils/helper' as h;

.pill {
  --pill--primary--color: var(--default-color);
  --pill--inverse--color: var(--white);
  --pill--font-family: var(--founders-cond);
  --pill--font-size: var(--text-16);
  --pill--font-weight: var(--normal);

  :global(body.msnbc) & {
    --pill--primary--color: var(--blue-40);
  }

  :global(body.select) & {
    --pill--primary--color: var(--blue-60);
    --pill--font-family: var(--lato);
    --pill--font-size: #{h.rem(14px)};
    --pill--font-weight: var(--normal);
  }

  :global(body.today) & {
    --pill--border-radius: 0;
    --pill--width: auto;
    --pill--font-family: var(--secondary-font);
    --pill--font-weight: 700;
    --pill--padding--horiztonal: #{h.rem(20px)};
  }
}

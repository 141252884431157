@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.cardByline {
  display: flex;
  margin-bottom: var(--spacing-5);
  margin-top: h.rem(8px);
  min-height: h.rem(28px);
}

.bylineThumbnail {
  margin: var(--cb--byline-thumbnail--margin);

  picture,
  img {
    border-radius: 50%;
    display: block;
    height: h.rem(36px);
    overflow: auto;
    width: h.rem(36px);
  }
}

.inner {
  display: flex;
}

.authors {
  align-self: center;
  color: var(--cb--authors--color);
  font-size: var(--cb--authors--font-size);
  font-family: var(--cb--authors--font-family);
  font-weight: var(--cb--authors--font-weight);
  line-height: var(--cb--authors--line-height);
  margin: var(--cb--authors--margin);
}

@use 'assets/styles/utils/helper' as h;

div.share-list__icons .social-share-menu__container {
  --social-share-menu-item-icon--color: var(--grey-60);
  --share-list-social-list-icon-margin: 0 #{h.rem(12px)};
  --share-list-social-list-icon-max-width: 1.875rem;
  --social-share-menu-copy--font-size: #{h.rem(20px)};

  body.news & {
    --social-share-menu-icon--font-size: 1.5rem;
    --social-share-menu-container--column-gap: 0;
    --social-share-menu-sms-icon--font-size: 1.75rem;
    --social-share-menu-icon--width: #{h.rem(48px)};
    --social-share-menu-item-icon--color: var(--grey-60);

    @include h.breakpoint-m {
      --social-share-menu-container--column-gap: 0;
    }

    .share-list__copy_icon {
      --social-share-menu-icon--font-size: #{h.rem(20px)};
    }

    .icon_link {
      --social-share-menu-icon--font-size: 1.25rem;
      --social-share-menu-container--column-gap: 40px;
    }
  }
}

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.liveBlogCards {
  display: flex;
  flex-direction: column;
  margin-top: var(--live-blog-posts--cards--margin-top);
}

.blog {
  margin-top: 48px;
}

.liveBlogCards,
.blog {
  &__scrollUpButtonContainer {
    --lb--top: calc(var(--lb--scrolling-up--top) + var(--election-nav-height, 0px));
    align-content: center;
    display: grid;
    position: sticky;
    z-index: h.get-z-index('sticky-ui-controls');
    transition: top 0.2s ease-in;
    top: var(--lb--top);
  }

  @mixin topRuleset {
    order: -1; // Move to the first position in source order
    top: var(--lb--top);
  }

  @mixin bottomRuleset {
    order: 0;
    bottom: var(--lb--backTotop-bottom-gutter);
  }

  &__scrollUpButtonContainer--mobileTop {
    @include topRuleset();
  }

  &__scrollUpButtonContainer--mobileBottom {
    @include bottomRuleset();
  }

  @include h.breakpoint-l {
    &__scrollUpButtonContainer--desktopTop {
      @include topRuleset();
    }

    &__scrollUpButtonContainer--desktopBottom {
      @include bottomRuleset();
    }
  }

  :global(.cardsContainer) {
    position: relative;
  }

  &__button {
    opacity: 1;
    margin: 0 auto;
    transition: opacity var(--lb--backTotop-hidden-duration) ease-out, transform 0.3s ease-out;
    grid-column: 1;
    grid-row: 1;
    pointer-events: auto;

    &:active,
    &:focus {
      outline: none;
    }

    &:active {
      span,
      path,
      rect {
        opacity: var(--default-active-opacity);
      }
    }

    &--hidden {
      transition: opacity var(--lb--backTotop-hidden-duration) ease-out, transform 0.2s ease-in;
      pointer-events: none;
      opacity: 0;
    }
  }

  &__posts-button {
    background-color: var(--pill--color);
    border: 1px solid var(--pill--color);
    color: var(--pill--text--inverse--color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--pill--text--inverse--color);
        background-color: var(--pill--hover--background-color, var(--pill--color));
        opacity: var(--pill--hover--opacity, 0.8);
      }
    }

    &:active {
      color: var(--pill--active--text--color, var(--pill--text--inverse--color));
      background-color: var(--pill--active--background-color, var(--pill--color));
      opacity: var(--pill--active--opacity, 0.6);
    }

    &--hidden {
      opacity: 0;
      transition: opacity var(--lb--backTotop-hidden-duration) ease-out, transform 0.2s ease-in;
      pointer-events: none;
    }

    &--pulse {
      animation: pulse 2s;
    }

    &--chat-style-redesign {
      font-family: Oswald, var(--founders-cond);
      font-weight: 500;
      border-radius: h.rem(4px);
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 var(--pill--pulse--color);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
      box-shadow: 0 0 0 40px rgba(255, 82, 82, 0);
    }
  }
}

.liveBlogCards__scrollUpButtonContainer {
  pointer-events: none;

  &:has(.liveBlogCards__posts-button--tandem-hover:hover, .liveBlogCards__button--tandem-hover:hover) {
    .liveBlogCards__posts-button--tandem-hover {
      background-color: var(--pill--hover--background-color);
      color: var(--pill--text--inverse--color);
      border-color: var(--pill--hover--background-color);
    }

    .liveBlogCards__button--tandem-hover {
      background-color: var(--btt--hover--primary-color);
      border: 1px solid var(--btt--hover--primary-color);

      path {
        stroke: var(--btt--hover--inverse-color);
      }

      rect {
        fill: var(--btt--hover--inverse-color);
      }
    }
  }
}

@use 'assets/styles/utils/helper' as h;

.replies {
  border-left: 0.15rem solid var(--grey-60);
  padding-left: h.rem(24px);
}

.repliesCount {
  font-family: var(--oswald-font-family);
  padding-bottom: h.rem(16px);
  color: var(--grey-60);
  text-transform: uppercase;
  font-weight: 500;
  font-size: h.rem(16px);
}

:global(.article-body .article-body__content) .replies .hasParent {
  > p,
  > p:first-of-type {
    margin: 1.5rem 0;
  }

  & figure {
    margin-top: 1.5rem;

    @include h.breakpoint-s-only {
      width: 100%;
      margin-left: 0;
    }
  }

  & figcaption {
    @include h.breakpoint-s-only {
      margin-left: 0;
    }
  }
}

@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.chatCardEyebrow {
  background-color: #102039;
  font-size: 15px;
  font-family: var(--oswald-font-family);
  line-height: 1.15;
  color: #fff;
  margin-bottom: 4px;
  border-radius: 0 0 3px 3px;
  padding: 3px 8px 4px;
  max-width: max-content;
}

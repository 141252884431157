@use 'assets/styles/utils/helper' as h;

.wrapper {
  margin-bottom: h.rem(100px);
  margin-top: h.rem(42px);
  position: relative;
  max-height: h.rem(230px);
  padding: h.rem(20px);

  @include h.breakpoint-x {
    margin-left: h.rem(-100px);
  }

  &::before {
    content: '';
    width: 100vw;
    height: 100%;
    background: var(--grey-20);
    position: absolute;
    display: block;
    transform: skewY(2deg);
    left: h.rem(-20px);

    @include h.breakpoint-m {
      width: h.rem(616px);
    }

    @include h.breakpoint-l {
      left: h.rem(-40px);
    }

    @include h.breakpoint-x {
      width: h.rem(695px);
    }
  }
}

.fade::after {
  content: '';
  width: 100vw;
  position: absolute;
  height: h.rem(55px);
  transform: translateY(-43px) skewY(2deg);
  background: linear-gradient(to bottom, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 100%);
  left: h.rem(-20px);
  z-index: 1;

  @include h.breakpoint-m {
    width: h.rem(616px);
  }

  @include h.breakpoint-l {
    left: h.rem(-40px);
  }

  @include h.breakpoint-x {
    width: h.rem(695px);
  }
}

.textContent {
  max-height: h.rem(200px);
  width: calc(100vw - 13%);
  padding-right: h.rem(36px);
  position: relative;
  margin-top: h.rem(18px);
  overflow-y: scroll;

  @include h.breakpoint-m {
    width: h.rem(550px);
  }

  @include h.breakpoint-l {
    width: h.rem(530px);
  }

  @include h.breakpoint-x {
    width: h.rem(615px);
  }
}

.header {
  font-family: var(--secondary-font);
  font-size: h.rem(18px);
  font-weight: 700;
  position: relative;
  padding-left: h.rem(12px);
  margin: h.rem(12px 0);

  @include h.breakpoint-m {
    font-size: h.rem(24px);
  }
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: h.rem(6px);
  list-style-type: none;
  position: relative;

  @include h.breakpoint-m {
    padding-bottom: h.rem(8px);
  }
}

.link {
  font-size: h.rem(16px);
  text-decoration: underline;
  text-decoration-color: var(--accent-orange);
  text-underline-offset: h.rem(6px);
  padding-left: h.rem(12px);
  margin-top: h.rem(3px);

  @include h.breakpoint-m {
    font-size: h.rem(20px);
    margin-top: 0;
  }

  &:hover {
    color: var(--primary-color);
  }
}

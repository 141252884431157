@use 'assets/styles/utils/helper' as h;

.cardByline {
  --cb--byline-thumbnail--margin: 0 var(--spacing-2) 0 0;
  --cb--authors--color: var(--grey-60);
  --cb--authors--font-size: #{h.rem(16px)};
  --cb--authors--font-family: var(--founders-cond);
  --cb--authors--font-weight: normal;
  --cb--authors--line-height: var(--leading-100);
  --cb--authors--margin: 0;

  :global(body.select) & {
    --cb--authors--color: #555;
    --cb--authors--font-family: var(--lato);
    --cb--authors--font-weight: 900;
    --cb--authors--margin: 0 0 4px 0;
  }

  :global(body.today) & {
    --cb--authors--font-family: var(--secondary-font);
    --cb--authors--color: var(--grey-70);
  }
}

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens';

.backToTop {
  align-items: center;
  background-color: var(--btt--primary--color);
  border: 1px solid var(--btt--primary--color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  display: flex;
  height: h.rem(42px);
  justify-content: center;
  overflow-y: hidden;
  padding: 0;
  width: h.rem(42px);

  path {
    stroke: var(--btt--inverse--color);
  }

  rect {
    fill: var(--btt--inverse--color);
  }

  &:hover {
    background-color: var(--btt--hover--primary-color, var(--btt--inverse--color));
    border: 1px solid var(--btt--hover--primary-color, var(--btt--primary--color));

    path {
      stroke: var(--btt--hover--inverse-color, var(--btt--primary--color));
    }

    rect {
      fill: var(--btt--hover--inverse-color, var(--btt--primary--color));
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.isVisible {
    opacity: 1;
    pointer-events: all;
  }
}

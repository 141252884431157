@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.article-inline-byline {
  color: var(--article-body--byline--color);
  font-family: var(--article-body--byline--font-family);
  font-size: var(--article-body--byline--font-size);
  font-weight: var(--article-body--byline--font-weight);
  line-height: var(--article-body--byline--line-height);
  margin: var(--article-body--byline--margin);
}

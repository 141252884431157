@use 'assets/styles/utils/helper' as h;

.container {
  margin: h.rem(0 0 65px 20px);
  overflow: hidden;

  @include h.breakpoint-m {
    margin: h.rem(0 40px 80px);
  }

  @include h.breakpoint-l {
    max-width: h.rem(920px);
    margin: 0 auto h.rem(100px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(1160px);
  }
}

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.chatCartByline {
  display: flex;
  margin-bottom: 0;
  margin-top: h.rem(4px);
  min-height: h.rem(28px);
  gap: 5px;
}

.inner {
  display: flex;
}

.authors {
  align-self: center;
  line-height: var(--cb--authors--line-height);
  margin: var(--cb--authors--margin);
  flex-wrap: nowrap;
}

@use 'assets/styles/utils/helper' as h;

.chat-blog-card {
  --lbc--stripes--background: rgba(48, 97, 255, 0.5);
  --cc--before--border-color: #102039;
  --lbc--share-link--color: var(--grey-60);
  --share-list-color: var(--grey-60);

  // shared pullquote and liftout rules
  --lbc--pq-lo--font-size: #{h.rem(20px)};
  --lbc-small--font-size: #{h.rem(16px)};
  --lbc--pq-lo--font-weight: var(--light);
  --lbc--pq-lo--line-height: var(--leading-125);
  --last-body-item-background-gradient: rgba(255, 255, 255, 0);
  --last-body-item-background-gradient-base: rgba(255, 255, 255, 1);
  --lbc--share-link--width: #{h.rem(24px)};
  --lbc--share-link--height: #{h.rem(24px)};
  --chat-card--border-style: solid;
  --figcaption--margin-top: #{h.rem(16px)};
  --lbc--avatar-bg-color: var(--blue-70);
}

.shows {
  --lbc--stripes--background: var(--blue-40);
}

body.msnbc .chat-blog-card {
  --lbc--avatar-bg-color: var(--blue-40);
}

.back-to-top-button {
  --back-to-top-button-background-color: var(--default-color);
  --back-to-top-button-hover-opacity: var(--opacity-70);

  body.news & {
    --back-to-top-button-background-color: var(--blue-40);
  }

  body.better & {
    --back-to-top-button-background-color: var(--teal-30);
  }

  body.today & {
    --back-to-top-button-background-color: var(--accent-blue);
    --back-to-top-button-hover-opacity: 1;
  }
}

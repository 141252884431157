@use 'assets/styles/utils/helper' as h;

.title {
  margin: 0;
  font-family: var(--founders-cond);
  font-style: normal;
  font-weight: var(--semibold);
  font-size: var(--text-20);
  line-height: var(--leading-100);
}

.title,
.titleLink {
  flex-shrink: 0;
}

.titleLink:hover {
  opacity: var(--opacity-70);
}

.titleLink:active {
  opacity: var(--opacity-50);
}

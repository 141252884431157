@use 'assets/styles/utils/helper' as h;

.msnbcDailyRecircEnd {
  // container rules
  font-family: var(--founders-cond);
  font-weight: normal;
  color: var(--grey-70);
  margin-bottom: h.rem(48px);

  .head {
    display: block;
    margin-bottom: h.rem(20px);
    padding: 0 h.rem(20px);

    @include h.breakpoint-m {
      display: flex;
      flex-flow: row nowrap;
      padding: 0;
    }

    @include h.breakpoint-x {
      padding-left: h.rem(60px);
    }

    .title {
      font-size: h.rem(32px);
      font-weight: normal;
      line-height: 1;
      margin: 0 0 h.rem(10px) 0;
      text-transform: uppercase;

      @include h.breakpoint-m {
        font-size: h.rem(48px);
        flex-grow: 1;
        flex-shrink: 0;
        margin: 0 h.rem(30px) 0 0;
      }
    }

    .description {
      font-family: var(--publico-txt);
      font-weight: normal;
      font-size: h.rem(14px);
      line-height: 1.5;
      margin: 0;

      @include h.breakpoint-m {
        flex-grow: 0;
        flex-shrink: 1;
        font-size: h.rem(16px);
        line-height: 1.25;
        padding-top: h.rem(8px);
      }
    }
  }

  .body {
    padding: 0 h.rem(20px);

    @include h.breakpoint-m {
      padding: 0;
    }

    @include h.breakpoint-l {
      padding: 0 h.rem(80px) 0 0;
    }

    @include h.breakpoint-x {
      padding: 0 h.rem(60px);
    }
  }

  .item {
    margin-bottom: h.rem(20px);

    @include h.breakpoint-m {
      display: flex;
      margin-bottom: auto;
      padding-bottom: h.rem(30px);

      &:nth-child(1) { order: 1; }
      &:nth-child(2) { order: 4; }
      &:nth-child(3) { order: 2; }
      &:nth-child(4) { order: 5; }
      &:nth-child(5) { order: 3; }
      &:nth-child(6) { order: 6; }
    }

    .info {
      margin-bottom: auto;
    }

    .active {
      background-color: var(--yellow-40);
      display: inline-block;
      font-size: h.rem(14px);
      line-height: 1;
      margin: 0 h.rem(12px) 0 0;
      padding: h.rem(6px 10px);
      text-transform: uppercase;
    }

    .unibrow {
      font-family: var(--founders-mono);
      font-weight: normal;
      color: var(--blue-40);
      display: block;
      font-size: h.rem(12px);
      line-height: 1;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      white-space: nowrap;

      &.isActive {
        margin: h.rem(8px) 0 0;

        @include h.breakpoint-x {
          display: inline-block;
        }
      }
    }

    .title {
      display: block;
      font-size: h.rem(20px);
      line-height: 1;
      margin: h.rem(8px) 0 0 0;
      padding: 0;
    }

    .externalLink {
      font-family: var(--founders-cond);
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
      color: var(--blue-40);
      margin-top: 10px;

      :global(.icon) {
        font-size: 11px;
        margin: 4px 0 0 4px;
      }
    }
  }

  .borderTop {
    @include h.breakpoint-m {
      border-top: 1px solid var(--black);
      margin-bottom: h.rem(30px);
    }

    @include h.breakpoint-l {
      margin-right: h.rem(80px);
    }

    @include h.breakpoint-x {
      margin-left: h.rem(60px);
      margin-right: h.rem(60px);
    }
  }

  .borderBottom {
    height: h.rem(8px);
    margin: 0 h.rem(20px);
    position: relative;

    @include h.breakpoint-m {
      margin: 0;
    }

    @include h.breakpoint-l {
      margin-right: h.rem(80px);
    }

    @include h.breakpoint-x {
      margin-left: h.rem(60px);
      margin-right: h.rem(60px);
    }
  }
}

@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

@keyframes zoomAndFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.live-blog-card {
  scroll-margin-top: 68px;

  &::before {
    border-bottom: h.rem(1px) var(--card--border-style) var(--lbc--before--border-color);
    content: '';
    display: block;
    width: 100%;
  }

  > h3,
  a > h3,
  a > h2 {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    font-size: 20px !important;
  }

  > h4,
  a > h4 {
    font-size: 18px !important;
  }

  @include h.breakpoint-s-only {
    > p,
    .body-list-el li {
      font-size: var(--lbc-small--font-size);
    }
  }

  .body-list-el,
  > .liftout,
  > .related {
    margin: h.rem(24px) 0;
  }

  > .blockquote {
    margin: h.rem(48px 0 32px 9px);
  }

  > .pullquote {
    margin: h.rem(48px) 0 h.rem(32px);
  }

  .pullquote__quote,
  .liftout__text {
    font-size: var(--lbc--pq-lo--font-size);
    font-weight: var(--lbc--pq-lo--font-weight);
    line-height: var(--lbc--pq-lo--line-height);
  }

  figcaption {
    margin-bottom: h.rem(24px);
  }

  &__byline-thumbnail {
    picture {
      width: h.rem(36px);
      margin: h.rem(24px);
    }

    a:hover {
      opacity: 1;
    }
  }

  p a:hover {
    opacity: var(--opacity-70);
  }

  @include h.breakpoint-m {
    // Header + Maximum Ad Height + 8px padding top
    scroll-margin-top: 350px;
  }

  .hidden-show-more-content {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
}

// Increased specificity to override article-body styles
.article-body .live-blog-card.article-body__content {
  --article-body-ol-li-margin: 0 0 #{h.rem(24px)} 6px;
  --article-body-ol-font-size: 18px;

  .body-list-el > li {
    margin-left: 0;

    &::before {
      font-size: 18px;
      vertical-align: baseline;
      width: auto;
      margin-right: 0.5em;
    }
  }

  .body-list-el:last-child {
    margin-bottom: 0;
  }

  .break-above::before {
    content: none;
  }

  .embed-widget {
    margin: h.rem(24px) 0;
  }
}

.cardsContainer > :not(.lastCard).card:last-child {
  // not pretty but Intersection Observer currently creates another <div> which would enable this rule
  height: 148px;
  overflow: hidden;
  position: relative;

  &::after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, rgba(255, 255, 255, 0.78648) 56.77%, #fff 100%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.live-blog-card.newActiveItem {
  animation: zoomAndFadeIn 0.3s ease-in 0s 1 normal forwards;
}

.share-list__wrapper {
  display: flex;
  align-items: center;

  .share__link-copy {
    margin: h.rem(0 12px);

    .icon-link {
      color: var(--lbc--share-link--color);

      &:hover {
        opacity: var(--opacity-70);
      }
    }
  }

  :global(body.today .share--card) {
    width: h.rem(20px);
    height: h.rem(20px);
    margin-right: 0;
    border: none;
    font-size: var(--share-list-icon-size-s);
    line-height: h.rem(30px);

    &:hover {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .share__sms {
    margin-left: h.rem(12px);
    margin-right: h.rem(12px);
    line-height: 0;

    .icon-sms {
      height: var(--lbc--share-link--height);
      width: var(--lbc--share-link--width);

      path {
        fill: var(--lbc--share-link--color);
      }
    }
  }
}

@use 'assets/styles/utils/helper' as h;

:global(.today .layout-header) {
  height: 60px;
}

:global(.select .layout-header) {
  height: 60px;

  @include h.breakpoint-m {
    height: 80px;
  }
}

:global(.select .layout-header) > :global(.hfsh) > :global(.mobilenav),
:global(.today .layout-header) > :global(.hfsh) > :global(.mobilenav) {
  display: none;
}

.tableOfContents {
  position: sticky;
  top: 60px;
  transition: transform 0.5s ease;
  transform: translateY(0);
  width: 100%;
  z-index: 100;

  @include h.breakpoint-l {
    position: unset;
    margin-bottom: 40px;
  }

  :global(.select) & {
    top: var(--space-112);
  }

  &.hidden {
    transform: translateY(-60px);
    max-height: 0;
  }
}

:root {
  --live-blog-button-active-opacity: var(--default-active-opacity);
  --live-blog-button-font-color: var(--blue-40);
  --live-blog-button-background-color: var(--white);
  --live-blog-loading-button-font-color: var(--white);
  --live-blog-loading-button-background-color: var(--blue-40);
  --live-blog-hover-button-font-color: var(--white);
  --live-blog-hover-button-background-color: var(--blue-40);
}

.msnbc {
  --live-blog-loading-button-background-color: var(--blue-70);
}

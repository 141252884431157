.jumpLinkList {
  background-color: transparent;
  font-family: var(--default-text-font-family);
  font-size: var(--text-12);
  font-style: normal;
  font-weight: 400;
  line-height: var(--spacing-16);
  list-style-type: none;
  margin: 0;
  width: 100%;

  :global(.select) & {
    font-family: var(--lato);
    font-size: var(--text-17);
    line-height: 170%;
  }

  :global(.today) & {
    font-family: Mada, sans-serif;
    font-size: var(--text-18);
    line-height: 150%;
  }
}

.jumpLinkList__item {
  border-left: none;
  padding-bottom: var(--text-12);
  padding-left: var(--text-12);

  :global(.select) & {
    border-left: 2px solid var(--blue-60);
  }

  :global(.today) & {
    border-left: 2px solid #ffb186;
  }
}

.jumpLinkList__item:last-child {
  padding-bottom: 0;
}

.jumpLinkList__itemLink {
  color: var(--grey-70);
  opacity: var(--opacity-100);
  text-decoration: none;

  &:focus,
  &:hover {
    :global(.select) & {
      color: var(--blue-60);
      opacity: var(--opacity-70);
      text-decoration: none;
    }

    :global(.today) & {
      color: var(--orange-30);
      text-decoration: underline;
      text-decoration-color: #ffb186;
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;
    }
  }
}

@use 'assets/styles/utils/helper' as h;

.logo {
  object-fit: contain;
}

.logoLink:hover {
  opacity: var(--opacity-70);
}

.logoLink:active {
  opacity: var(--opacity-50);
}

@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.chatCardTimestamp {
  --chatStyle-ts--text-color: var(--red-50, #cb1111);
  margin-top: var(--card-timestamp--margin-top);
  padding-bottom: var(--card-timestamp--padding-bottom);
  margin-bottom: var(--card-timestamp--margin-bottom);
  white-space: nowrap;

  &.eventEnded {
    --chatStyle-ts--text-color: var(--grey-60, #555);
  }
}

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.back-to-top-button {
  background-color: transparent;
  border: none;
  bottom: var(--space-20);
  outline: none;
  position: fixed;
  right: var(--space-36);
  z-index: h.get-z-index('back-to-top-button');
  padding: 0;
  opacity: 0;
  pointer-events: none;

  body.today & {
    path {
      stroke: var(--black);
    }

    rect:last-child {
      fill: var(--black);
    }
  }

  :hover {
    opacity: var(--back-to-top-button-hover-opacity);
  }

  &.isVisible {
    opacity: 1;
    pointer-events: all;
  }
}

.back-to-top-button__square {
  fill: var(--back-to-top-button-background-color);

  body.today .back-to-top-button:hover & {
    fill: var(--accent-purple);
  }
}

@include h.breakpoint-s-only {
  .back-to-top-button {
    bottom: var(--space-8);
  }
}

@include h.breakpoint-m-only {
  .back-to-top-button {
    bottom: var(--space-20);
  }
}

@use 'assets/styles/utils/helper' as h;

:root {
  // This non-namespaced :root rule is problematic
  --default-background: var(--white);
  --lb--top: #{h.rem(80px)};
  --lb--scrolling-up--top: var(--lb--top);
  --lb--backTotop-bottom-gutter: 5.6875rem;
  --lb--backTotop-hidden-duration: 0.3s;
  --lb--backTotop-hidden-delay: 3s;
  --pill--text--inverse--color: var(--white);
  --pill--color: var(--default-color);
  --pill--pulse--color: var(--default-color);
  --live-blog-posts--cards--margin-top: #{h.rem(48px)};
}

.msnbc,
.news {
  --pill--color: var(--breaking-red);
  --pill--pulse--color: var(--breaking-red);
}

.deportes,
.noticias {
  --pill--color: var(--noticias-red-50);
  --pill--pulse--color: var(--noticias-red-50);
}

.deportes,
.telemundo,
.noticias,
.entretenimiento,
.think,
.shows {
  // Global navigation is present when scrolling up
  @include h.breakpoint-m {
    --lb--top: #{h.rem(148px)};
    --lb--scrolling-up--top: #{h.rem(148px)};
  }
}

.select {
  --pill--color: var(--blue-60);
  --pill--pulse--color: var(--blue-60);

  @include h.breakpoint-m {
    --lb--top: #{h.rem(100px)};
    --lb--scrolling-up--top: var(--lb--top);
  }
}

.today {
  --pill--color: var(--accent-blue);
  --pill--text--inverse--color: var(--grey-70);
  --pill--hover--opacity: 1;
  --pill--hover--background-color: var(--accent-purple);
  --pill--active--text--color: var(--grey-70);
  --pill--active--opacity: 1;
  --pill--active--background-color: var(--accent-purple);
  --live-blog-posts--cards--margin-top: #{h.rem(6px)};
}

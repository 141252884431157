@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

/* Important: Making changes to this file could have an impact on shorthand articles */

.expanded-byline-contributors {
  margin-top: h.rem(48px);
}

.expanded-byline {
  margin-bottom: -1px;
  padding: var(--expanded-byline--padding);
  border-top: var(--expanded-byline--border-top);
  border-bottom: var(--expanded-byline--border-bottom);
  position: relative;
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto 1fr auto;

  body.today &::before {
    clip-path: polygon(0 0, 100% 40%, 100% 100%, 0% 100%);
    background: var(--accent-orange);
    content: '';
    width: 100%;
    height: 16px;
    position: absolute;
    top: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: var(--expanded-byline--margin-bottom, -1px);
  }
}

.expanded-byline__bio-wrapper {
  grid-area: var(--expanded-byline--bio--grid-area);
}

.expanded-byline__bio {
  color: var(--expanded-byline--bio--color);
  font-family: var(--expanded-byline--bio--font-family);
  font-size: var(--expanded-byline--bio--font-size);
  font-weight: var(--expanded-byline--bio--font-weight);
  line-height: var(--expanded-byline--bio--line-height);
  margin: var(--expanded-byline--bio--margin);

  a {
    color: var(--expanded-byline--bio-link--color);
    text-decoration-line: var(--expanded-byline--bio-link--text-decoration-line);
    text-decoration-color: var(--expanded-byline--bio-link--text-decoration-color);
    text-decoration-thickness: var(--expanded-byline--bio-link--text-decoration-thickness);
    text-underline-offset: var(--expanded-byline--bio-link--text-underline-offset);

    &:hover {
      color: var(--primary-color);
    }
  }
}

.expanded-byline__name {
  font-family: var(--expanded-byline--name--font-family);
  font-size: var(--expanded-byline--name--font-size);
  font-weight: var(--expanded-byline--name--font-weight);
  letter-spacing: var(--expanded-byline--name--letter-spacing);
  line-height: var(--expanded-byline--name--line-height);
  padding-top: var(--expanded-byline--name--padding-top);
  margin-right: var(--expanded-byline--name--margin-right);
  display: block;
  grid-area: var(--expanded-byline--name--grid-area);

  a:hover {
    opacity: var(--opacity-70);
  }
}

.expanded-byline__social {
  display: block;
  font-size: var(--expanded-byline--social--font-size);
  margin: var(--expanded-byline--social--margin);
  grid-area: var(--expanded-byline--social--grid-area);

  @include h.breakpoint-m {
    text-align: var(--expanded-byline--social--text-align);
  }

  .byline-social--link {
    &:not(:last-of-type) {
      margin-right: h.rem(18px);
    }

    &:hover {
      color: var(--expanded-byline--social-link-hover-color);
    }
  }

  @media print {
    display: none;
  }
}

.expanded-byline__thumbnail {
  display: block;
  margin-right: var(--expanded-byline--thumbnail--margin-right);
  grid-area: var(--expanded-byline--thumbnail--grid-area);

  picture,
  img {
    background-color: #f1f0ed;
    width: var(--expanded-byline--thumbnail--width);
    height: var(--expanded-byline--thumbnail--height);
  }

  &.byline-thumbnail--no-image {
    width: var(--expanded-byline--thumb-no-image--width);
    margin: var(--expanded-byline--thumb-no-image--margin);
    display: var(--expanded-byline--thumb-no-image--display);

    // mr0-print
    @media print {
      margin-right: 0 !important;
    }

    &::before {
      background-color: var(--expanded-byline--image-fallback-line-color);
      content: ' ';
      width: 100%;
      height: h.rem(1px);
      display: block;
    }
  }
}

.expanded-contributors {
  color: var(--expanded-contributors--color);
  font-family: var(--expanded-contributors--font-family);
  font-size: var(--expanded-contributors--font-size);
  font-weight: var(--expanded-contributors--font-weight);
  letter-spacing: var(--expanded-contributors--letter-spacing);
  line-height: var(--expanded-contributors--line-height);
  border-color: var(--expanded-contributors--border-color);
  border-style: solid;
  border-width: 1px 0;
  padding: var(--expanded-contributors--padding);
  text-transform: var(--expanded-contributors--text-transform);

  &::before {
    content: ' ';
    width: h.rem(48px);
    height: h.rem(1px);
    display: var(--expanded-contributors--before--display);
    background-color: var(--expanded-contributors-line--background-color);
    margin-right: h.rem(16px);
    margin-left: -4rem;
    vertical-align: middle;
  }
}

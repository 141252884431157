@use 'assets/styles/utils/helper' as h;

.countChip {
  background-color: #555;
  color: var(--white);
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  margin-left: -5px;
  border: 1.5px solid #fff;
  padding-top: 1px;
  padding-right: 1px;
  cursor: default;
}

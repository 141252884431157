@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.chatCardByline {
  font-family: var(--chat-blog--author-name--font-family);
  font-size: var(--chat-blog--byline--text);
  line-height: 135%;
  display: box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  a,
  a:link,
  a:visited {
    text-decoration: underline;
  }

  a:hover {
    opacity: 0.7;
  }

  &--name {
    color: var(--chat-blog--author-name--color);
    font-weight: 500;
  }

  &--bio {
    color: var(--chat-blog--authors-bio--color);
    font-weight: 400;
    margin: 0;
    display: box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  &--shortbio {
    margin-left: 8px;
  }
}

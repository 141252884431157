@use 'assets/styles/utils/helper' as h;

/* Important: Making changes to this file could have an impact on shorthand articles */
:root {
  --expanded-byline--inner--margin-top: 8px;
  --expanded-byline--border-top: 1px solid var(--grey-40);
  --expanded-byline--border-bottom: 1px solid var(--grey-40);
  --expanded-byline--padding: #{h.rem(20px)} 0 #{h.rem(8px)};
  --expanded-byline--headshot--background-color: #f1f0ed;
  --expanded-byline--social-link-hover-color: var(--default-color);
  --expanded-byline--social--margin: 8px 0 0 0;
  --expanded-byline--social--text-align: right;
  --expanded-byline--social--font-size: 12px;
  --expanded-byline--image-fallback-line-color: var(--default-color);
  --expanded-byline--name--color: inherit;
  --expanded-byline--name--font-family: var(--founders-mono);
  --expanded-byline--name--font-size: var(--text-14);
  --expanded-byline--name--font-weight: 400;
  --expanded-byline--name--letter-spacing: -0.04em;
  --expanded-byline--name--line-height: 1.25;
  --expanded-byline--name--padding-top: #{h.rem(4px)};
  --expanded-byline--name--margin-right: 0;
  --expanded-byline--bio--color: var(--grey-70);
  --expanded-byline--bio--font-family: var(--publico-txt);
  --expanded-byline--bio--font-size: var(--text-12);
  --expanded-byline--bio--font-weight: 400;
  --expanded-byline--bio--line-height: 1.5;
  --expanded-byline--bio--margin: #{h.rem(12px)} 0;
  --expanded-byline--bio-link--color: var(--default-color);
  --expanded-byline--bio-link--text-decoration-line: none;
  --expanded-contributors--color: var(--grey-70);
  --expanded-contributors--font-family: var(--founders-mono);
  --expanded-contributors--font-size: var(--text-12);
  --expanded-contributors--font-weight: 400;
  --expanded-contributors--letter-spacing: -0.04em;
  --expanded-contributors--line-height: var(--text-18);
  --expanded-contributors--border-color: var(--grey-40);
  --expanded-contributors-line--background-color: var(--default-color);
  --expanded-contributors--padding: #{h.rem(20px 0 20px 64px)};
  --expanded-contributors--text-transform: none;
  --expanded-contributors--before--display: inline-block;
  --expanded-byline--thumbnail--margin-right: #{h.rem(16px)};
  --expanded-byline--thumbnail--width: var(--space-48);
  --expanded-byline--thumbnail--height: var(--space-48);
  --expanded-byline--thumb-no-image--display: block;
  --expanded-byline--thumb-no-image--width: var(--space-48);
  --expanded-byline--thumb-no-image--margin: #{h.rem(12px)} #{h.rem(16px)} 0 0;
  --expanded-byline--thumbnail--grid-area: 1 / 1 / 3 / 2;
  --expanded-byline--name--grid-area: 1 / 2 / 2 / 4;
  --expanded-byline--social--grid-area: 2 / 2 / 3 / 4;
  --expanded-byline--bio--grid-area: 3 / 1 / 4 / 4;

  @include h.breakpoint-m {
    --expanded-byline--padding: #{h.rem(12px)} 0 #{h.rem(8px)};
    --expanded-byline--social--margin: 16px 0 0 0;
    --expanded-byline--inner--margin-top: 0;
    --expanded-byline--name--padding-top: #{h.rem(16px)};
    --expanded-byline--thumb-no-image--margin: #{h.rem(24px)} #{h.rem(16px)} #{h.rem(24px)} 0;
    --expanded-byline--bio--margin: 0 0 #{h.rem(12px)} #{h.rem(64px)};
    --expanded-byline--name--grid-area: 1 / 2 / 2 / 3;
    --expanded-byline--social--grid-area: 1 / 3 / 2 / 4;
  }
}

body.globalcitizen {
  --expanded-byline--social-link-hover-color: var(--red-40);
  --expanded-byline--image-fallback-line-color: var(--red-40);
  --expanded-byline--bio-link--color: var(--red-40);
}

body.select {
  --expanded-byline--name--color: #2a2a2a;
  --expanded-byline--name--font-family: var(--lato);
  --expanded-byline--name--font-size: #{h.rem(18px)};
  --expanded-byline--name--font-weight: 900;
  --expanded-byline--name--letter-spacing: 0;
  --expanded-byline--name--line-height: #{h.rem(18px)};
  --expanded-byline--bio--color: #555;
  --expanded-byline--bio--font-family: var(--lato);
  --expanded-byline--bio--font-size: #{h.rem(12px)};
  --expanded-byline--image-fallback-line-color: var(--blue-60);
  --expanded-contributors--color: #555;
  --expanded-contributors--font-family: var(--lato);
  --expanded-contributors--font-size: #{h.rem(12px)};
  --expanded-contributors--letter-spacing: 0;
  --expanded-contributors--line-height: #{h.rem(18px)};
  --expanded-contributors-line--background-color: var(--blue-60);
}

body.today {
  --expanded-byline--border-top: none;
  --expanded-byline--border-bottom: none;
  --expanded-byline--padding: #{h.rem(32px)} 0 0;
  --expanded-byline--name--font-family: var(--secondary-font);
  --expanded-byline--name--font-size: #{h.rem(18px)};
  --expanded-byline--name--line-height: 1.5;
  --expanded-byline--name--padding-top: 0;
  --expanded-byline--name--margin-right: #{h.rem(20px)};
  --expanded-byline--thumbnail--margin-right: #{h.rem(20px)};
  --expanded-byline--thumbnail--width: #{h.rem(60px)};
  --expanded-byline--thumbnail--height: #{h.rem(60px)};
  --expanded-byline--thumb-no-image--display: none;
  --expanded-byline--social--margin: 7px 0 0 0;
  --expanded-byline--social--font-size: 14px;
  --expanded-byline--bio--font-family: var(--tertiary-font);
  --expanded-byline--bio--color: var(--grey-60);
  --expanded-byline--bio--margin: 0;
  --expanded-byline--bio-link--color: var(--grey-60);
  --expanded-byline--bio-link--text-decoration-line: underline;
  --expanded-byline--bio-link--text-decoration-color: var(--accent-orange);
  --expanded-byline--bio-link--text-decoration-thickness: #{h.rem(2px)};
  --expanded-byline--bio-link--text-underline-offset: #{h.rem(3px)};
  --expanded-contributors--padding: #{h.rem(20px 0)};
  --expanded-contributors--before--display: none;
  --expanded-contributors--font-family: var(--secondary-font);
  --expanded-contributors--text-transform: uppercase;
  --expanded-contributors--border-color: var(--accent-orange);
  --expanded-contributors--letter-spacing: 0;
  --expanded-contributors--font-weight: 700;
  --expanded-contributors--color: var(--grey-60);
  --expanded-byline--social--text-align: left;
  --expanded-byline--name--grid-area: 1 / 2 / 2 / 3;
  --expanded-byline--social--grid-area: 1 / 3 / 2 / 4;
  --expanded-byline--bio--grid-area: 2 / 2 / 3 / 4;
  --expanded-byline--margin-bottom: 40px;

  @include h.breakpoint-m {
    --expanded-byline--name--font-size: #{h.rem(20px)};
  }
}

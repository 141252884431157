@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.reportingFrom {
  font-family: var(--oswald-font-family);
  line-height: 1.35;
  gap: 3px;
  color: var(--color);
  align-items: baseline;
  display: flex;
}

.icon {
  transform: translateY(1px);
  flex-shrink: 0;
}

.icon path {
  fill: var(--color);
}

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

// overiding body styles for cards
// CardHeadline h2s have different treatment than those in the card body
h2.chartCardHeadline,
:global(.article-body .article-body__content) > h2.chartCardHeadline {
  color: var(--chart-card-headline--color);
  font-family: var(--chart-card-headline--font-family);
  font-size: var(--chart-card-headline--font-size);
  font-weight: var(--chart-card-headline--font-weight);
  line-height: var(--chart-card-headline--line-height);
  margin: 0 0 12px;
}

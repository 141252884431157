@use 'assets/styles/utils/helper' as h;

button:focus-visible {
  outline: 2px solid #0db14b;
  outline-offset: 0;
}

.accordion {
  background-color: var(--white);
  border-top: 1px solid var(--grey-40);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

  @include h.breakpoint-l {
    border-top: unset;
    box-shadow: unset;
  }
}

.accordion.open {
  padding-bottom: 12px;
}

.accordion__header {
  align-items: normal;
  background-color: var(--white);
  border: none;
  color: var(--grey-70);
  cursor: pointer;
  display: flex;
  font-family: Mada, sans-serif;
  font-size: var(--text-18);
  font-style: normal;
  font-weight: 700;
  justify-content: space-between;
  line-height: 150%;
  outline: none;
  padding: 13px 20px;
  width: 100%;

  :global(.select) & {
    font-family: var(--lato);
    font-size: var(--text-18);
    font-style: normal;
    font-weight: 900;
    line-height: 150%;

    @include h.breakpoint-l {
      font-size: var(--text-20);
      line-height: 125%;
    }
  }

  :global(.today) & {
    font-family: Mada, sans-serif;
    font-size: var(--text-20);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    @include h.breakpoint-l {
      font-size: var(--text-24);
    }
  }

  @include h.breakpoint-l {
    padding: 13px 0;
  }
}

.accordion__header:disabled {
  cursor: default;
  width: 100%;
}

.accordion__icon {
  font-size: 1.2em;
  transform: rotate(0deg);
}

.accordion__icon.down {
  transform: rotate(180deg);
}

.accordion__content {
  background-color: var(--white);
  padding: 0 20px;
  position: absolute;
  width: 100%;

  @include h.breakpoint-l {
    padding: 0;
    position: unset;
    width: unset;
    background-color: unset;
  }
}

.accordion__content.closed {
  display: none;
}

.accordion__content.open {
  display: block;
  max-height: 60vh;
  overflow: auto;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);

  @include h.breakpoint-l {
    max-height: unset;
    box-shadow: none;
  }
}

.affordance {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none; /* Allows clicks to pass through */
}

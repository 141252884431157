@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.bylineThumbnail {
  margin: 0;
  border: 1.5px solid #fff;
  border-radius: 50%;
  background-color: var(--avatar-bg-color);
  display: block;

  picture,
  img,
  svg {
    border-radius: 50%;
    display: block;
    height: h.rem(36px);
    overflow: auto;
    width: h.rem(36px);
  }

  a:hover {
    opacity: 1;
  }
}

.chatCardBylineThumbnail:nth-child(2) {
  .bylineThumbnail {
    margin-left: -5px;
  }
}

@use 'assets/styles/utils/helper' as h;

.topicSubNav {
  --inline-gutter: var(--space-20);
  --height: 48px;
  position: sticky;
  top: 0;
  z-index: h.get-z-index('sticky-sub-navigation');
  background-color: var(--white);
  display: grid;
  justify-content: center;
  border-bottom: 1px solid var(--grey-40);
  overflow-y: hidden;

  @include h.breakpoint-l {
    --inline-gutter: var(--space-40);
  }
}

.topicSubNav::before,
.topicSubNav::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: var(--height);
}

.topicSubNav::before {
  background-color: #f2f2f2;
  width: 50vw;
  left: 0;
}

.topicSubNav::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 1) 100%);
  width: var(--inline-gutter);
  right: 0;
  z-index: 1;
}

:root:has(.topicSubNav) {
  --lb--top: 68px;
}

.scrollingContent,
.linkItems {
  display: flex;
  align-items: center;
}

.topicSubNavInner,
.pageBtnContainer {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.topicSubNavInner {
  position: relative;
  overflow-x: auto;
  display: flex;
  max-width: 1310px;
}

.topicSubNavInner::-webkit-scrollbar {
  display: none;
}

.scrollingContent {
  padding-inline: var(--inline-gutter);
  flex-shrink: 0;
  min-width: 100%;
  justify-content: center;
  height: var(--height);
  column-gap: 20px;
}

.linkItems {
  --column-spacing: var(--space-12);
  column-gap: var(--column-spacing);
  list-style: none;
  margin: 0;
  flex-shrink: 0;
  background-color: #fff;
  padding: h.rem(13px) 20px;
}

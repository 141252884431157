@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.cardTimestamp {
  --ts--text-color: var(--red-50, #cb1111);
  --ts--font-weight: 400;
  --ts--letter-spacing: -0.5px;
  --ts--font-family: var(--founders-cond);
  margin-top: var(--card-timestamp--margin-top);
  padding-bottom: var(--card-timestamp--padding-bottom);
  margin-bottom: var(--card-timestamp--margin-bottom);

  &.eventEnded {
    --ts--text-color: var(--grey-70, #2a2a2a);
  }

  &.noWrap {
    white-space: nowrap;
  }

  /* stylelint-disable no-descending-specificity */
  :global(body.today) & {
    --ts--letter-spacing: 0;

    &:not(.eventEnded) {
      --ts--text-color: #ff503c;
    }
  }

  :global(body.select) & {
    &:not(.eventEnded) {
      --ts--text-color: #0477c9;
    }

    &.eventEnded {
      --ts--text-color: #171717;
    }
  }

  :global(body.telemundo) & {
    &:not(.eventEnded) {
      --ts--text-color: #c31010;
    }
  }
  /* stylelint-enable no-descending-specificity */
}

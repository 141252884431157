@use 'assets/styles/utils/helper' as h;

.linkItem {
  --highlighter-color: var(--red-50);
  flex-shrink: 0;
  position: relative;
  cursor: default;
  padding-right: var(--column-spacing);
  border-right: 1px solid var(--grey-40);

  &:last-of-type {
    border-right: none;
  }
}

.link {
  font-family: var(--founders-cond);
  font-size: var(--text-18);
  font-weight: var(--semibold);
  list-style: var(--leading-100);
  color: var(--grey-70);
}

a.link:hover {
  opacity: var(--opacity-70);
}

a.link:active {
  opacity: var(--opacity-50);
}

.underline::after {
  content: '';
  width: calc(100% - var(--column-spacing));
  height: 1px;
  position: absolute;
  bottom: -6px;
  left: 0;
  background-color: var(--grey-70);
}

.highlighted .link {
  color: var(--highlighter-color);
}

.highlighted.underline::after {
  background-color: var(--highlighter-color);
}

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens';

.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  height: h.rem(32px);
  width: var(--pill--width, h.rem(226px));
  color: var(--pill--inverse--color);
  background-color: var(--pill--primary--color);
  border-radius: var(--pill--border-radius, h.rem(100px));
  border: 1px solid var(--pill--primary--color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  font-family: var(--pill--font-family);
  font-size: var(--pill--font-size);
  font-weight: var(--pill--font-weight);
  padding: 0 var(--pill--padding--horiztonal, #{h.rem(10px)}) #{h.rem(1px)} var(--pill--padding--horiztonal, #{h.rem(6px)});

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--pill--inverse--color);
      color: var(--pill--primary--color);
    }
  }
}

.countLabel {
  padding: 0 0 0 h.rem(6px);

  :global(body.today) & {
    //necessary for specificity override
    &:active {
      opacity: 1;
    }
  }
}

.backToTopIcon {
  background: inherit;
  width: h.rem(16px);
  height: h.rem(16px);
}

@use 'assets/styles/utils/helper' as h;

.cardHeadline {
  --card-headline--color: inherit;
  --card-headline--font-family: var(--founders-cond);
  --card-headline--font-size: var(--text-24);
  --card-headline--font-weight: var(--normal);
  --card-headline--line-height: var(--leading-100);
  --card-headline--margin: 0 0 #{h.rem(20px)} 0;

  :global(body.select) & {
    --card-headline--color: var(--grey-70);
    --card-headline--font-family: var(--lato);
    --card-headline--font-size: 20px;
    --card-headline--font-weight: 900;
    --card-headline--line-height: var(--leading-125);
  }

  :global(body.today) & {
    --card-headline--color: var(--grey-70);
    --card-headline--font-family: var(--primary-font);
    --card-headline--font-size: var(--text-20);
    --card-headline--line-height: 1.5;

    @include h.breakpoint-m {
      --card-headline--font-size: #{h.rem(24px)};
    }
  }
}

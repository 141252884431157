@use 'assets/styles/utils/helper' as h;

.cardTimestamp {
  --card-timestamp--margin-top: #{h.rem(8px)};
  --card-timestamp--margin-bottom: #{h.rem(8px)};
  --card-timestamp--padding-bottom: #{h.rem(4px)};

  :global(body.today) & {
    --card-timestamp--margin-top: #{h.rem(12px)};
    --card-timestamp--margin-bottom: #{h.rem(12px)};
    --card-timestamp--padding-bottom: #{h.rem(8px)};

    @include h.breakpoint-x {
      --card-timestamp--margin-top: #{h.rem(20px)};
    }
  }
}
